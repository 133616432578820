import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  Box,
  Button,
  FormField,
  Input,
  Separator,
  SideModal,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';

import { useUploadFile } from '../../_common/hooks';
import { TextEditorWithFileUpload } from '../../_common/modules/TextEditorWithFileUpload';
import { adminApi } from '../../_common/services/api';

const { useSendSupportEmailMutation } = adminApi;

export const AdminReportIssueModal = () => {
  const { hide } = useModal();
  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Report an Issue</SideModal.Title>
      </SideModal.Header>
      <ReportIssueForm onSuccess={hide}>
        <SideModal.Body>
          <Typography.p>
            Please provide a clear description of the issue that you have
            encountered.
          </Typography.p>
          <br />
          <Typography.p>
            After you click Submit an email will be sent to us with all the
            details. You will also be cc'd in this conversation.
          </Typography.p>
          <Separator />
          <Box>
            <ReportIssueFormSubjectField />
          </Box>
          <Box>
            <ReportIssueFormMessageField />
          </Box>
        </SideModal.Body>
        <SideModal.Actions>
          <SideModalReportIssueFormError />
          <ReportIssueFormSubmitButton />
        </SideModal.Actions>
      </ReportIssueForm>
    </SideModal.Content>
  );
};

type ReportIssueFormContextType = {
  isLoading: boolean;
  error: string;
  onChangeSubject: (e: { target: { value: string } }) => void;
  onChangeMessage: (htmlText: string, markdownText: string) => void;
  onSubmit: () => Promise<void>;
  isSubmitDisabled: boolean;
  subject: string;
  message: string;
};
// @ts-ignore
const ReportIssueFormContext = createContext<ReportIssueFormContextType>(null);

type ReportIssueFormProps = {
  onSuccess: () => void;
  persistFormState?: boolean;
  initialMessage?: string;
  initialSubject?: string;
  children: React.ReactNode;
};
export const ReportIssueForm = ({
  onSuccess,
  persistFormState = true,
  initialSubject: _initialSubject = undefined,
  initialMessage: _initialMessage = undefined,
  children,
}: ReportIssueFormProps) => {
  const { initialValues, resetPersistance, updatePersistance } =
    useHydrateFromLocalStorage(persistFormState);
  const initialSubject =
    typeof _initialSubject !== 'undefined' ? _initialSubject : initialValues[0];
  const initialMessage =
    typeof _initialMessage !== 'undefined' ? _initialMessage : initialValues[1];
  const [subject, setSubject] = useState(initialSubject);
  const [message, setMessage] = useState(initialMessage);
  const [markdownMessage, setMarkdownMessage] = useState(initialValues[0]);
  const {
    onUpload: onUploadLogs,
    isLoading: isUploadLogsLoading,
    errorMessage: logsUploadError,
  } = useUploadFile({
    type: 'text_editor_asset',
  });
  const {
    onUpload: onUploadLocalStorage,
    isLoading: isUploadLocalStorageLoading,
    errorMessage: localStorageUploadError,
  } = useUploadFile({
    type: 'text_editor_asset',
  });
  const [sendSupportEmail, { isLoading, error }] =
    useSendSupportEmailMutation();
  const errorMessage = getTranslatedMessageFromError(error);
  const onChangeSubject = useCallback(
    (e) => {
      setSubject(e.target.value);
      updatePersistance(e.target.value, message, markdownMessage);
    },
    [message, markdownMessage],
  );
  const onChangeMessage = useCallback(
    (value, markdownMessage) => {
      setMarkdownMessage(markdownMessage);
      setMessage(value);
      updatePersistance(subject, value, markdownMessage);
    },
    [subject],
  );
  const onSubmit = useCallback(async () => {
    const bugReportId = `${sessionStorage.getItem(
      'spanId',
    )}-${new Date().toISOString()}`;
    const logsBlob = new Blob(
      // @ts-ignore
      [JSON.stringify(console.logs ? console.logs : [])],
      {
        type: 'application/json',
      },
    );
    const logsFile = new File([logsBlob], `${bugReportId}-logs.json`);
    const localStorageBlob = new Blob([JSON.stringify(dumpLocalStorage())], {
      type: 'application/json',
    });
    const localStorageFile = new File(
      [localStorageBlob],
      `${bugReportId}-local-storage.json`,
    );
    const [logs_file_url, local_storage_file_url] = await Promise.all([
      onUploadLogs(logsFile),
      onUploadLocalStorage(localStorageFile),
    ]);
    const result = await sendSupportEmail({
      subject,
      message,
      metadata: {
        location: window.location.pathname,
        markdown_message: markdownMessage,
        logs_file_url,
        local_storage_file_url,
      },
    });
    if (result.data) {
      resetPersistance();
      onSuccess();
    }
  }, [subject, message, markdownMessage]);
  const isSubmitDisabled = !(
    !!message &&
    !!subject &&
    message !== InitialMessage
  );

  return (
    <ReportIssueFormContext.Provider
      value={{
        onSubmit,
        isLoading:
          isLoading || isUploadLogsLoading || isUploadLocalStorageLoading,
        isSubmitDisabled,
        error: errorMessage || logsUploadError || localStorageUploadError,
        onChangeMessage,
        onChangeSubject,
        subject,
        message,
      }}
    >
      {children}
    </ReportIssueFormContext.Provider>
  );
};

export const ReportIssueFormSubjectField = ({ label = 'Subject' }) => {
  const { subject, onChangeSubject } = useContext(ReportIssueFormContext);
  return (
    <FormField label={label} required>
      <Input
        value={subject}
        name="subject"
        onChange={onChangeSubject}
        placeholder="Short description of the issue"
      />
    </FormField>
  );
};
export const ReportIssueFormMessageField = ({ label = 'Message' }) => {
  const { message, onChangeMessage } = useContext(ReportIssueFormContext);
  return (
    <FormField label={label} required>
      <TextEditorWithFileUpload
        initialValue={message}
        onChange={onChangeMessage}
      />
    </FormField>
  );
};

export const ReportIssueFormSubmitButton = ({
  label = 'Submit',
  disabled = false,
}) => {
  const { isLoading, onSubmit, isSubmitDisabled } = useContext(
    ReportIssueFormContext,
  );

  return (
    <Button
      isLoading={isLoading}
      disabled={disabled || isSubmitDisabled}
      onClick={onSubmit}
      label={label}
    />
  );
};

const SideModalReportIssueFormError = () => {
  const { error } = useContext(ReportIssueFormContext);

  // @ts-ignore
  return <SideModal.Error error={error} />;
};

const dumpLocalStorage = () => {
  const allLocalStorage = {};
  const storageKeys = Object.keys(localStorage);
  storageKeys.forEach((storageKey) => {
    if (storageKey.includes('token') || storageKey === 'user') {
      return;
    }
    allLocalStorage[storageKey] = localStorage.getItem(storageKey);
  });

  return allLocalStorage;
};

const useHydrateFromLocalStorage = (persistFormState) => {
  const [initialSubject, initialMessage, initialMarkdownMessage] =
    useMemo(() => {
      if (!persistFormState) return ['', InitialMessage, ''];
      const storageState = localStorage.getItem('bug-report-modal');
      try {
        if (!storageState) return ['', InitialMessage, ''];
        const parsedState = JSON.parse(storageState);
        if (!Array.isArray(parsedState)) return ['', InitialMessage, ''];
        return parsedState;
      } catch (e) {
        return ['', InitialMessage, ''];
      }
    }, []);

  const resetPersistance = useCallback(() => {
    if (!persistFormState) return;
    localStorage.setItem(
      'bug-report-modal',
      JSON.stringify(['', InitialMessage, '']),
    );
  }, [persistFormState]);

  const updatePersistance = useCallback(
    (subject, message, markdownMessage) => {
      if (!persistFormState) return;
      localStorage.setItem(
        'bug-report-modal',
        JSON.stringify([subject, message, markdownMessage]),
      );
    },
    [persistFormState],
  );

  return {
    initialValues: [initialSubject, initialMessage, initialMarkdownMessage],
    resetPersistance,
    updatePersistance,
  };
};
const InitialMessage = `<p style=""><strong>Summary</strong></p><p style="">Tell us briefly what happened.</p><p style=""></p><p style=""><strong>Steps to reproduce</strong></p><p style="">If the problem is more complex you can specify detailed steps on how to replicate it.</p><p style=""></p><p style=""><strong>Screenshots</strong></p><p style="">You can attach screenshots to make it easier for us to understand what went wrong. Just close this form, capture an image of the issue and then paste it or drop it in this input. </p><p style="">Keep in mind not to show any potentially sensitive data.</p>`;
