import { EndpointDefinitions, createApi } from '@reduxjs/toolkit/query/react';
import { AppRouter, initContract } from '@ts-rest/core';

import { ApiClientOptions, SdkEndpointType } from '../../types';
import { axiosTsRestBaseQuery, parseRtkQueryArgs } from './lib';
import { RtkDefaultRouter, RtkEndpoints } from './types';

export function build<
  R extends Record<string, RtkDefaultRouter>,
  T extends RtkEndpoints<R>,
>(instance, router: R, options: ApiClientOptions & { reducerPath: string }) {
  return createApi({
    reducerPath: options.reducerPath,
    baseQuery: axiosTsRestBaseQuery(),
    endpoints: (builder): T =>
      Object.keys(router).reduce((acc, key) => {
        return {
          ...acc,
          [key]: (() => {
            let method: typeof builder.query | typeof builder.mutation =
              builder.query;
            if (
              router[key].method === 'GET' ||
              router[key].description === SdkEndpointType.Query
            ) {
              method = builder.query;
            } else if (
              router[key].method === 'POST' ||
              router[key].method === 'PUT' ||
              router[key].method === 'DELETE' ||
              router[key].method === 'PATCH' ||
              router[key].description === SdkEndpointType.Mutation
            ) {
              method = builder.mutation;
            }

            return method({
              query: (args) => async () => {
                const props = parseRtkQueryArgs(args, router[key]);
                return instance[key](props);
              },
            });
          })(),
        };
      }, {}) as unknown as T,
  });
}
