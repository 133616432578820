import { useEffect, useMemo, useRef } from 'react';

import { RtkBaseQuery } from './types';

export const axiosTsRestBaseQuery = (): RtkBaseQuery => async (apiMethod) => {
  const result = await apiMethod();
  // @ts-ignore
  if (result.status >= 400) return { error: result.body };
  // @ts-ignore
  return { data: result.body };
};

export const parseRtkQueryArgs = (args, routerEndpoint) => {
  if (Array.isArray(args)) {
    return { query: {}, params: {}, body: args };
  } else if (typeof args === 'function') {
    throw new Error('Invalid query args');
  } else {
    const params = args?.params ? { ...args, ...args.params } : args || {};
    const query = args?.query || {};
    const body = args;

    return { query, params, body };
  }
};

type QueryPollingProps = {
  refetch: () => unknown;
  interval?: number;
  shouldRefetch?: boolean;
};
export const useQueryPolling = ({
  refetch,
  interval = 5000,
  shouldRefetch = true,
}: QueryPollingProps) => {
  const intervalTimer = useRef<ReturnType<typeof setInterval> | null>(null);
  useEffect(() => {
    if (shouldRefetch) {
      intervalTimer.current = setInterval(refetch, interval);
    } else if (intervalTimer.current) {
      clearInterval(intervalTimer.current);
    }
  }, [shouldRefetch]);
};
