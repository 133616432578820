import React, { useCallback, useContext, useMemo } from 'react';

import { FieldType } from '@nl-lms/common/shared';
import { Widget } from '@nl-lms/feature/dashboard/sdk';
import {
  PaginatedReportQuery,
  ReportStatus,
} from '@nl-lms/feature/reports/sdk';
import {
  Button,
  Card,
  ErrorPlaceholder,
  Icon,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  PrettyDate,
  Table,
  Tooltip,
  useConfirmationModal,
} from '@nl-lms/ui/components';
import {
  ListViewProvider,
  useListViewContext,
  useListViewTableData,
} from '@nl-lms/ui/modules';

import { reportsApi } from '../../../_common/services/api';
import { useShowUpsertReportSideModal } from '../AdminAnalytics/AdminAnalyticsReportForms/AdminAnalyticsUpsertReportSideModal';
import { useShowAdminDashboardAddReportWidgetSideModal } from './AdminDashboardAddReportWidgetSideModal';
import { AdminDashboardContext } from './AdminDashboardContext';

const { useGetReportDataQuery } = reportsApi;

export const AdminDashboardReportWidget = ({
  widget,
  index,
}: {
  widget: Widget<PaginatedReportQuery>;
  index: number;
}) => {
  const { activeDashboard, onRemoveWidget } = useContext(AdminDashboardContext);
  const { payload, label } = widget;
  const showReportEditModal = useShowUpsertReportSideModal();

  const showConfirmationModal = useConfirmationModal({
    message: 'Do you want to remove this?',
  });
  const onClickRemoveWidget = useCallback(async () => {
    const confirmResult = await showConfirmationModal();
    if (confirmResult.confirmed) {
      onRemoveWidget(index);
    }
  }, [index]);
  const showEditModal = useShowAdminDashboardAddReportWidgetSideModal();
  const onClickEditWidget = useCallback(() => {
    showEditModal({ widget });
  }, [widget]);

  const onClickGenerateReport = useCallback(() => {
    showReportEditModal({
      report: {
        // @ts-ignore
        id: 'test',
        name: widget.payload.name,
        label: widget.label,
        // @ts-ignore
        query: widget.payload,
        status: ReportStatus.Completed,
      },
    });
  }, [widget]);

  return (
    <Card>
      <Card.Header>
        <Card.HeaderTitle>{label}</Card.HeaderTitle>
        <Card.HeaderActions>
          {/*// @ts-ignore */}
          <Card.HeaderAction onClick={onClickGenerateReport}>
            <Tooltip title="Generate Report">
              <Icon.DownloadIcon />
            </Tooltip>
          </Card.HeaderAction>
          {/*// @ts-ignore */}
          <Card.HeaderAction onClick={onClickEditWidget}>
            <Tooltip title="Edit Widget">
              <Icon.EditIcon />
            </Tooltip>
          </Card.HeaderAction>
          {/*// @ts-ignore */}
          <Card.HeaderAction onClick={onClickRemoveWidget}>
            <Tooltip title="Remove Widget">
              <Icon.DeleteIcon />
            </Tooltip>
          </Card.HeaderAction>
          <Card.ExpandAction />
        </Card.HeaderActions>
      </Card.Header>
      <Card.Content paddingType="none">
        <ListViewProvider
          name={widget.label}
          // @ts-ignore
          baseFilters={widget.payload.query.filters}
          useUrlQuery={false}
          persistToLocalStorage={false}
          paginationLimit={10}
        >
          <AdminDashboardReportDataListTable reportQuery={widget.payload} />
        </ListViewProvider>
      </Card.Content>
    </Card>
  );
};

const AdminDashboardReportDataListTable = ({
  reportQuery,
}: {
  reportQuery: PaginatedReportQuery;
}) => {
  const { onChangePagination, sorting, query } = useListViewContext();
  const requestQuery = useMemo(() => {
    return {
      ...reportQuery,
      query,
    };
  }, [reportQuery, query]);
  const { data, error, refetch, isLoading } = useGetReportDataQuery(
    // @ts-ignore
    requestQuery,
    {
      pollingInterval: 30000,
    },
  );
  // @ts-ignore
  const [rows, pagination] = useListViewTableData(data);
  return (
    <Table
      data={rows.map((r, index) => ({ ...r, id: index }))}
      // @ts-ignore
      pagination={pagination}
      onChangePagination={onChangePagination}
      columns={reportQuery.columns
        .filter((col) => col.isVisible)
        .map((col) => ({
          Header: col.label,
          accessor: col.label,
          Cell: ({ row }) => {
            const value = row.original[col.label];
            if (col.type === FieldType.date && value) {
              return <PrettyDate value={value} withTime />;
            }
            if (typeof value === 'boolean') {
              return value ? 'True' : 'False';
            }
            if (Array.isArray(value)) return value.join(', ');
            return value;
          },
        }))}
      isLoading={isLoading}
    >
      {error ? (
        <ErrorPlaceholder>
          <ErrorPlaceholder.Image />
          <ErrorPlaceholder.Title />
          <ErrorPlaceholder.Description>
            Something went wrong during the fetching process. Try to refresh the
            table data or if the problem persists please get in contact with us
            using the app help channel
          </ErrorPlaceholder.Description>
          <Button label="Reload" onClick={refetch} />
        </ErrorPlaceholder>
      ) : (
        <NoDataPlaceholder
          iconName={'TableIcon'}
          color={NoDataPlaceholderColor.warning}
          title="Your configured report does not return any data"
          subtitle="You can try adjusting your filters"
        />
      )}
    </Table>
  );
};
