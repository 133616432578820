import * as z from 'zod';

import {
  ReportDataColumnSchema,
  ReportQueryNotificationSchema,
  ReportQuerySchema,
  ReportSchema,
  ReportTemplateNameSchema,
  ReportTemplateSchema,
} from '@nl-lms/common/feature/types';
import {
  AppQueryFiltersSchema,
  AppQueryPaginationSchema,
  AppQuerySchema,
  TimeDeltaSchema,
  TransformedDateSchema,
} from '@nl-lms/common/shared';

import {
  LearnerAttendance,
  LearnerLearnedTime,
  LearnerStatistics,
  LiveCourseNps,
  LiveCourseStatistics,
  ReportQueryNotification,
  ReportTemplateName,
} from './report.types.sdk';

const ReportNotificationSchema: z.ZodType<ReportQueryNotification> = z.object({
  message: z.string(),
  subject: z.string(),
  title: z.string(),
});

export { ReportTemplateNameSchema };

export const ScheduleReportQuerySchema = z.object({
  description: z.string(),
  name: ReportTemplateNameSchema,
  label: z.string(),
  recipientIds: z.array(z.string()),
  notification: ReportNotificationSchema,
  columns: z.array(ReportDataColumnSchema),
  scheduleDate: TransformedDateSchema,
  recurrenceDelta: TimeDeltaSchema.nullable(),
  recurrenceUntilDate: TransformedDateSchema.nullable(),
  withHistoricalLearnerData: z.boolean(),
  timezone: z.string(),
  query: AppQuerySchema.nullable(),
});

export const UpdateReportQuerySchema = z.object({
  description: z.string(),
  name: ReportTemplateNameSchema,
  label: z.string(),
  recipientIds: z.array(z.string()),
  notification: ReportQueryNotificationSchema,
  columns: z.array(ReportDataColumnSchema),
  withHistoricalLearnerData: z.boolean(),
  scheduleDate: TransformedDateSchema.nullable(),
  timezone: z.string(),
  recurrenceDelta: TimeDeltaSchema.nullable(),
  recurrenceUntilDate: TransformedDateSchema.nullable(),
  query: AppQuerySchema.nullable(),
});

export const GenerateReportQuerySchema = ReportQuerySchema.omit({
  learnerId: true,
});

export const ReportResponseSchema = ReportSchema;
export const ReportTemplateResponseSchema = ReportTemplateSchema;

export const PaginatedReportQuerySchema = z.object({
  description: z.string(),
  name: ReportTemplateNameSchema,
  label: z.string(),
  recipientIds: z.array(z.string()),
  notification: ReportNotificationSchema,
  columns: z.array(ReportDataColumnSchema),
  scheduleDate: z.date().nullable(),
  recurrenceDelta: TimeDeltaSchema.nullable(),
  recurrenceUntilDate: z.date().nullable(),
  withHistoricalLearnerData: z.boolean(),
  timezone: z.string(),
  query: z.object({
    filters: AppQueryFiltersSchema.optional(),
    pagination: AppQueryPaginationSchema,
  }),
});

export const CreateReportTemplateSchema = z.object({
  name: ReportTemplateNameSchema,
  label: z.string(),
  description: z.string(),
  columns: z.array(ReportDataColumnSchema),
  targetLearnerGroupIds: z.array(z.string()),
  notification: ReportNotificationSchema,
  query: AppQuerySchema.nullable(),
  withHistoricalLearnerData: z.boolean(),
});

export const ListReportTemplateSchema = ReportTemplateSchema.extend({
  targetLearnerGroups: z.array(z.object({ id: z.string(), title: z.string() })),
});

export const ReportIdParamSchema = z.object({ reportId: z.string() });
export const ReportTemplateIdParamSchema = z.object({
  reportTemplateId: z.string(),
});

export const ListLiveCourseStatisticsSchema: z.ZodType<LiveCourseStatistics> =
  z.object({
    id: z.string(),
    name: z.string(),

    npsSamples: z.number(),
    npsPromoters: z.number(),
    npsPassives: z.number(),
    npsDetractors: z.number(),
    npsScore: z.number(),
    averageCompletionRate: z.number(),
    averageAttendanceRate: z.number(),
    averageFillRate: z.number(),
    averageAttendees: z.number(),
    averageAbsents: z.number(),
  });

export const ListLearnerStatisticsSchema: z.ZodType<LearnerStatistics> =
  z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    customerInternalId: z.string(),
    depth: z.number(),
    totalMandatoryAssignments: z.number(),
    completedMandatoryAssignments: z.number(),
    incompleteMandatoryAssignments: z.number(),
    elearningLearnedHours: z.number(),
    iltLearnedHours: z.number(),
    viltLearnedHours: z.number(),
    totalLearnedHours: z.number(),
    totalCost: z.number(),
  });

export const GetLearnerAttendanceSchema: z.ZodType<LearnerAttendance> =
  z.object({
    totalLearners: z.number(),
    learnersWithAnyElearnings: z.number(),
    learnersWithOptionalElearnings: z.number(),
    anyElearningsPercentage: z.number(),
    optionalElearningsPercentage: z.number(),
    learnersWithAnyIlts: z.number(),
    learnersWithOptionalIlts: z.number(),
    anyIltsPercentage: z.number(),
    optionalIltsPercentage: z.number(),
    learnersWithAnyVilts: z.number(),
    learnersWithOptionalVilts: z.number(),
    anyViltsPercentage: z.number(),
    optionalViltsPercentage: z.number(),
  });

export const GetLearnerLearnedTimeSchema: z.ZodType<LearnerLearnedTime> =
  z.object({
    totalLearners: z.number(),
    anyElearningsHoursLearned: z.number(),
    anyIltsHoursLearned: z.number(),
    anyViltsHoursLearned: z.number(),
  });

export const GetLiveCourseNpsSchema: z.ZodType<LiveCourseNps> = z.object({
  averageNps: z.number(),
  meanNps: z.number(),
});

export const LearnerStatisticsParentFiltersSchema = z.string();

export const LiveLearningStatisticsParentFiltersSchema = z.string();
