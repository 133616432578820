export const LearnerGroupsRouterPrefix = '/organization/learner-group';
export const LearnersRouterPrefix = '/organization/learner';

export const LearnerScope = {
  list: { resource: 'learner', action: 'list' },
  edit: { resource: 'learner', action: 'edit' },
  create: { resource: 'learner', action: 'create' },
  deactivate: { resource: 'learner', action: 'deactivate' },
  activate: { resource: 'learner', action: 'activate' },
  export: { resource: 'learner', action: 'export' },
  import: { resource: 'learner', action: 'import' },
} as const;

export const LearnerGroupScope = {
  list: { resource: 'learner_group', action: 'list' },
  edit: { resource: 'learner_group', action: 'edit' },
  create: { resource: 'learner_group', action: 'create' },
  delete: { resource: 'learner_group', action: 'delete' },
  export: { resource: 'learner_group', action: 'export' },
  import: { resource: 'learner_group', action: 'import' },
  sudo: { resource: 'learner_group', action: 'sudo' },
} as const;

export const LearnerNotificationAudienceName = 'learner';
export const ManagersNotificationAudienceName = 'manager';
