import React, { useCallback, useMemo } from 'react';

import {
  AppQueryFilter,
  QueryFilterCombinator,
  QueryFilterOperatorType,
  QueryOperator,
} from '@nl-lms/common/shared';
import {
  Card,
  Icon,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { ListViewProvider, useListViewContext } from '@nl-lms/ui/modules';

import { TableRowAction } from '../../../../../../../../libs/ui/src/lib/components';
import { authStore } from '../../../../_common/modules/Auth/auth';
import { adminApi } from '../../../../_common/services/api';
import { AdminLiveSessionLearnerListTable } from '../../AdminLiveSession/AdminLiveSessionLearner/AdminLiveSessionLearnerListTable';
import './AdminLearningApprovalListCard.scss';

const {
  useConfirmLiveSessionLearnerMutation,
  useDeclineLiveSessionLearnerMutation,
} = adminApi;

export const AdminLearningApprovalListCard = ({
  // @ts-ignore
  learnerId = null,
  variation = 'light',
}: {
  variation?: 'light' | 'dark';
  learnerId?: string;
}) => {
  // @ts-ignore
  const baseFilters = useMemo<AppQueryFilter>(() => {
    const base = {
      id: 'approval-filters',
      value: [
        {
          id: 'status',
          value: {
            field: { label: 'status', field: 'status' },
            operator: {
              label: 'Equals',
              value: QueryOperator.Equals,
              type: QueryFilterOperatorType.Unary,
            },
            value: {
              label: 'Pending',
              value: C.I_LEARNER_STATUS.APPROVAL,
            },
          },
        },
        {
          id: 'approver-id',
          value: {
            field: { label: 'approverId', field: 'approverId' },
            operator: {
              label: 'Equals',
              value: QueryOperator.Equals,
              type: QueryFilterOperatorType.Unary,
            },
            value: {
              label: authStore?.user.learnerId,
              value: authStore?.user.learnerId,
            },
          },
          combinator: QueryFilterCombinator.And,
        },
      ],
    };

    if (learnerId) {
      base.value.push({
        id: 'learner-id',
        value: {
          field: { label: 'learnerId', field: 'learnerId' },
          operator: {
            label: QueryOperator.Includes,
            value: QueryOperator.Includes,
            type: QueryFilterOperatorType.Binary,
          },
          value: {
            label: learnerId,
            // @ts-ignore
            value: [learnerId],
          },
        },
        combinator: QueryFilterCombinator.And,
      });
    }
    return base;
  }, [learnerId]);
  return (
    <ListViewProvider
      key="admin-dashboard-learning-approvals"
      name="admin-dashboard-learning-approvals"
      paginationLimit={10}
      disablePagination={variation === 'dark'}
      useUrlQuery={false}
      persistToLocalStorage={false}
      initialSorting={[['createdAt', 'desc']]}
      baseFilters={baseFilters}
    >
      <AdminManagerLearningApprovalListTable variation={variation} />
    </ListViewProvider>
  );
};

export const AdminManagerLearningApprovalListTable = ({ variation }) => {
  const { query } = useListViewContext();

  const onClickConfirm = useConfirmSessionApproval();
  const onClickDecline = useDeclineSessionApproval();

  const rowActions: TableRowAction<unknown>[] = [
    {
      name: 'Approve',
      Icon: Icon.CheckIcon,
      handler: onClickConfirm,
      type: 'success',
    },
    {
      name: 'Decline',
      Icon: Icon.CloseIcon,
      handler: onClickDecline,
      type: 'alert',
    },
  ];

  const columnFilter = useCallback(
    (columns) =>
      columns.filter((column) =>
        [
          'learner.firstName',
          'learner.email',
          'trainingSession.name',
          'trainingSession.startDate',
        ].includes(column.accessor),
      ),
    [],
  );

  return (
    <Card className={`admin-learning-approval-list-table-${variation}`}>
      <Card.Header separatorMarginBottom={0}>
        <Card.HeaderTitle>Approvals</Card.HeaderTitle>
      </Card.Header>
      <Card.Content paddingType="none">
        <AdminLiveSessionLearnerListTable
          columnFilter={columnFilter}
          rowActions={rowActions}
        >
          <NoDataPlaceholder
            iconName={'UserPlusIcon'}
            color={NoDataPlaceholderColor.success}
            title={'There are no learning approvals'}
            subtitle={
              'Once someone registers to a sessions that requires approval, it will show up here.'
            }
          />
        </AdminLiveSessionLearnerListTable>
      </Card.Content>
    </Card>
  );
};

const useConfirmSessionApproval = () => {
  const [confirm] = useConfirmLiveSessionLearnerMutation();

  return useCallback(async (row) => {
    const res = await confirm([row.id]);
    return 'data' in res;
  }, []);
};

const useDeclineSessionApproval = () => {
  const [decline] = useDeclineLiveSessionLearnerMutation();

  return useCallback(async (row) => {
    const res = await decline([row.id]);
    return 'data' in res;
  }, []);
};
