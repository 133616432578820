import { yupResolver } from '@hookform/resolvers/yup';
import { add } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  IndividualLearning,
  IndividualLearningInstance,
  Learner,
  Vendor,
} from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  DateTime,
  FormField,
  Input,
  Separator,
  SideModal,
  Textarea,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { useNotifications } from '@nl-lms/ui/modules';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';

import { IndividualLearningSelect } from '../../../_common/modules/IndividualLearningSelect';
import { IndividualLearningTypeSelect } from '../../../_common/modules/IndividualLearningTypeSelect';
import { adminApi, useApi } from '../../../_common/services/api';
import { AdminLearnerSingleSelect } from '../AdminLearner/AdminLearnerSelect';
import { AdminVendorSelect } from '../AdminVendor/AdminVendorSelect';
import { AdminIndividualLearningDeliveryTypeSelect } from './AdminIndividualLearningDeliveryTypeSelect';

type Props = {
  instance: IndividualLearningInstance & {
    vendor: Vendor;
    mentorLearner: Learner;
    learner: Learner;
    individualLearning: IndividualLearning;
  };
};

const schema = yup.object().shape({
  name: yup.string().required(),
  type: yup.number().required(),
  deliveryType: yup.number().required(),
  learnerId: yup.string().required(),
  mentorLearnerId: yup.string().nullable(),
  vendorId: yup.string().nullable(),
  startDate: yup.date().nullable().required(),
  endDate: yup.date().nullable().required(),
  duration: yup.number().required(),
  details: yup.string(),
  individualLearningId: yup.string().nullable(),
});

const { useUpdateIndividualLearningInstanceMutation } = adminApi;

export const AdminIndividualLearningInstanceEditFormSideModal = ({
  instance,
}: Props) => {
  const [activeItem, setActiveItem] = useState();
  const { addSuccessNotification, addAlertNotification } = useNotifications();
  const api = useApi();

  const [mutate, { isLoading, error }] =
    useUpdateIndividualLearningInstanceMutation();

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      name: instance.name,
      type: instance.type,
      deliveryType: instance.deliveryType,
      startDate: new Date(instance.startDate),
      endDate: new Date(instance.endDate),
      duration: Math.round(instance.timeSpent / 60 / 60),
      learnerId: instance.learnerId,
      mentorLearnerId: instance.mentorLearnerId,
      individualLearningId: instance.individualLearningId,
      vendorId: instance.vendorId,
      details: instance.details,
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      const res = await mutate({
        ...data,
        id: instance.id,
        endDate: data.endDate.toISOString(),
        startDate: data.startDate.toISOString(),
        timeSpent: data.duration * 60 * 60,
      });

      // @ts-ignore
      if (res.error) {
        // @ts-ignore
        addAlertNotification({ message: res.error.message });
      } else {
        addSuccessNotification({
          message: 'Instance successfully updated',
        });
        // @ts-ignore
        onCloseModal({ success: true });
      }
    },
    [activeItem],
  );

  const onChangeIndividualLearning = useCallback(
    async (id) => {
      if (!id) {
        // @ts-ignore
        setValue('individualLearningId', null);
        // @ts-ignore
        setValue('type', null);
        // @ts-ignore
        setValue('name', null);
        // @ts-ignore
        setValue('vendorId', null);
        // @ts-ignore
        setValue('details', null);

        setActiveItem(undefined);
        return;
      }

      const item = (await api.individualLearning.get(id)) as any;

      setValue('name', item.name);
      setValue('type', item.type);
      setValue('individualLearningId', id);
      setValue('vendorId', item.vendorId);
      setValue('details', item.details);

      setActiveItem(item);
    },
    [setValue, control],
  );

  const onChangeStartDate = useCallback(
    async (data) => {
      const startDate = new Date(data.target.value);
      const endDate = new Date(watch('endDate'));
      const newEndDate = add(startDate, {
        seconds: (watch('duration') || 0) * 60 * 60,
      });

      setValue('startDate', data.target.value);
      if (newEndDate.getTime() > endDate.getTime()) {
        setValue('endDate', newEndDate);
      }
    },
    [setValue],
  );

  const onChangeEndDate = useCallback(
    async (data) => {
      setValue('endDate', data.target.value);
    },
    [setValue, watch],
  );

  const onChangeDuration = useCallback(
    (data) => {
      if (!data.target.value) return setValue('duration', 0);

      const _duration = parseInt(data.target.value as string);
      setValue('duration', _duration);

      const startDate = new Date(watch('startDate'));
      const endDate = watch('endDate') ? new Date(watch('endDate')) : null;
      const newEndDate = add(startDate, {
        seconds: _duration * 60 * 60,
      });

      if (!endDate) {
        setValue('endDate', newEndDate);
      } else if (newEndDate.getTime() > endDate.getTime()) {
        setValue('endDate', newEndDate);
      }
    },
    [setValue, watch],
  );

  const minEndDate = (() => {
    const duration = watch('duration') || 0;
    const startDate = new Date(watch('startDate'));

    return add(startDate, {
      seconds: duration * 60 * 60,
    });
  })();

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Edit Individual Instance</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Individual Learning</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Individual Learning"
              errorMessage={errors?.individualLearningId?.message}
            >
              <Controller
                name="individualLearningId"
                control={control}
                render={({ field }) => {
                  return (
                    <IndividualLearningSelect
                      {...field}
                      isAdmin={true}
                      initialSelectedItem={{
                        label: instance.individualLearning.name,
                        value: instance.individualLearningId,
                      }}
                      isClearable
                      placeholder={'Select Individual Learning'}
                      onChange={onChangeIndividualLearning}
                      hasError={!!errors?.individualLearningId?.message}
                    />
                  );
                }}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Name"
              required
              errorMessage={errors?.name?.message}
            >
              <Input
                {...register('name')}
                hasError={!!errors?.name?.message}
                placeholder="Name"
                required
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Vendor" required>
              <Controller
                control={control}
                name="vendorId"
                render={({ field }) => (
                  <AdminVendorSelect
                    {...field}
                    initialSelectedItem={field?.value || instance.vendorId}
                    selectedItem={field?.value as any}
                    isClearable
                    hasError={!!errors?.vendorId?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Type"
              errorMessage={errors?.type?.message}
              required
            >
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  // @ts-ignore
                  <IndividualLearningTypeSelect
                    {...field}
                    hasError={!!errors?.type?.message}
                  />
                )}
              />
            </FormField>
          </Box>
          <Box>
            <FormField
              label="Delivery Type"
              required
              errorMessage={errors?.deliveryType?.message}
            >
              <Controller
                name="deliveryType"
                control={control}
                render={({ field }) => (
                  <AdminIndividualLearningDeliveryTypeSelect
                    {...field}
                    // @ts-ignore
                    initialValue={instance.deliveryType}
                    hasError={!!errors?.deliveryType?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Mentor"
              // @ts-ignore
              errorMessage={errors.mentorLearnerId?.message}
            >
              <Controller
                name="mentorLearnerId"
                control={control}
                render={({ field }) => (
                  <AdminLearnerSingleSelect
                    {...field}
                    initialSelectedItem={
                      instance?.mentorLearner
                        ? {
                            label: `${instance.mentorLearner?.firstName} ${instance.mentorLearner?.lastName}`,
                            value: instance.mentorLearnerId,
                          }
                        : null
                    }
                    returnEntireItemOnChange={false}
                    isClearable={true}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Learner"
              required
              // @ts-ignore
              errorMessage={errors.learnerIds?.message}
            >
              <Controller
                name="learnerId"
                control={control}
                render={({ field }) => (
                  <AdminLearnerSingleSelect
                    {...field}
                    initialSelectedItem={{
                      label: `${instance.learner?.firstName} ${instance.learner?.lastName}`,
                      value: instance.learnerId,
                    }}
                    returnEntireItemOnChange={false}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Start Date"
              required
              errorMessage={errors?.startDate?.message}
            >
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DateTime
                    date={field.value}
                    onChange={onChangeStartDate}
                    hasError={errors?.startDate?.message}
                    placeholder="Start Date"
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="End Date"
              required
              errorMessage={errors?.endDate?.message}
            >
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <DateTime
                    date={field.value}
                    onChange={onChangeEndDate}
                    minDate={minEndDate}
                    hasError={errors?.endDate?.message}
                    placeholder="End date"
                  />
                )}
              />
            </FormField>
          </Box>
          <Box>
            <FormField
              label="Duration"
              required
              errorMessage={errors?.duration?.message as string}
            >
              <Controller
                name="duration"
                control={control}
                render={({ field }) => (
                  <Input
                    name="duration"
                    type="number"
                    value={field.value}
                    onChange={onChangeDuration}
                    placeholder="Hours"
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Details"
              // @ts-ignore
              errorMessage={errors?.details?.message}
            >
              <Controller
                name="details"
                control={control}
                render={({ field }) => (
                  <Textarea
                    name="details"
                    placeholder="Some details about the sessions"
                    onChange={(e) => field.onChange(e.target.value)}
                    value={field.value}
                    hasError={!!errors?.details?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {getTranslatedMessageFromError(error)}
        </SideModal.Error>
        <Button
          label="Save"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
